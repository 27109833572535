var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c("span", { staticClass: "secondary--text font-weight-bold" }, [
          _vm._v("API Keys"),
        ]),
      ]),
      _c("v-card-subtitle", [
        _vm._v(
          " Access tokens programmatically authenticate your identity to Nuvolos, allowing applications to perform specific actions. "
        ),
      ]),
      _c(
        "v-card-text",
        [
          _vm.requestRunning === "fetchApiKeys"
            ? _c("v-skeleton-loader", {
                staticClass: "mx-auto",
                attrs: { type: "table" },
              })
            : [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mr-2",
                        attrs: { color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.openDialog()
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [_vm._v("add")]),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("add new API key"),
                        ]),
                      ],
                      1
                    ),
                    _c("v-spacer"),
                    _c("v-text-field", {
                      staticClass: "mr-2",
                      attrs: {
                        label: "Search for an API key...",
                        autofocus: "",
                        "hide-details": "",
                        "prepend-inner-icon": "mdi-filter",
                        solo: "",
                        flat: "",
                        "background-color": "grey lighten-4",
                        dense: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          loading: _vm.requestRunning === "fetchApiKeys",
                          icon: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.fetchApiKeys()
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("refresh")])],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-data-table", {
                  attrs: {
                    items: _vm.apiKeys,
                    "item-key": "apid",
                    headers: _vm.headers,
                    search: _vm.search,
                    options: _vm.tableOptions,
                    "footer-props": {
                      "items-per-page-options": _vm.itemsPerPageOptions,
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "top",
                        fn: function ({ pagination, options, updateOptions }) {
                          return [
                            _c("v-data-footer", {
                              staticClass: "no-border",
                              attrs: {
                                pagination: pagination,
                                options: options,
                                itemsPerPageOptions: _vm.itemsPerPageOptions,
                                "items-per-page-text":
                                  "$vuetify.dataTable.itemsPerPageText",
                              },
                              on: { "update:options": updateOptions },
                            }),
                          ]
                        },
                      },
                      {
                        key: "no-data",
                        fn: function () {
                          return [
                            _c("span", [
                              _vm._v(
                                "No API keys have been added yet. Click add new API key to create a new one."
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: `item.apid`,
                        fn: function ({ item }) {
                          return [_vm._v(" " + _vm._s(item.apid) + " ")]
                        },
                      },
                      {
                        key: `item.type`,
                        fn: function ({ item }) {
                          return [
                            _c(
                              "v-chip",
                              { attrs: { color: "success", small: "" } },
                              [_vm._v(_vm._s(item.type))]
                            ),
                          ]
                        },
                      },
                      {
                        key: `item.valid_from`,
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dateTimeToHuman")(item.valid_from)
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: `item.valid_until`,
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dateTimeToHuman")(item.valid_until)
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: `item.actions`,
                        fn: function ({ item }) {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    icon: "",
                                                    small: "",
                                                    color: "secondary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openDialog()
                                                    },
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("mdi-rotate-left")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("Rotate API key")])]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    small: "",
                                                    color: "secondary",
                                                    loading:
                                                      _vm.requestRunning ===
                                                      "delete",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openDeleteDialog(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("delete")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("Delete API key")])]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
        ],
        2
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px", persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Your new API key")]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "mt-5 mb-6",
                      attrs: { type: "info", text: "", dense: "" },
                    },
                    [
                      _c("strong", [
                        _vm._v(
                          "Be advised that you won't be able to view the API key later!"
                        ),
                      ]),
                    ]
                  ),
                  _vm.requestRunning === "newApiKey"
                    ? _c("dashboard-loader", [_vm._v("Generating API key")])
                    : [
                        !_vm.$isError(_vm.apiKey)
                          ? [
                              _c(
                                "code",
                                { staticClass: "text-body-1 py-2 px-4 mr-2" },
                                [_vm._v(" " + _vm._s(_vm.apiKey) + " ")]
                              ),
                              _c("copy-to-clipboard", {
                                attrs: {
                                  textToCopy: _vm.apiKey,
                                  tooltip: "Copy API key to clipboard",
                                  iconButton: true,
                                },
                              }),
                            ]
                          : _vm._e(),
                        _vm.$isError(_vm.apiKey)
                          ? _c("dashboard-error")
                          : _vm._e(),
                      ],
                ],
                2
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        color: "primary",
                        disabled: _vm.requestRunning === "newApiKey",
                      },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("Ok")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }